<template>
    <div class="vvt-preview">
        <v-card
            flat
            tile
            color="secondary"
            :loading="$wait.is('fetch record')">
            <v-toolbar 
                tile
                color="primary"
                flat
                :height="toolbarHeight"
            >
                <v-tooltip
                    v-if="$hasRole('ROLE_EDITOR')"
                    :disabled="$vuetify.breakpoint.mdAndUp"
                    color="primary"
                    :nudge-left="$vuetify.breakpoint.smAndDown ? -25 : null"
                    bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            color="white"
                            tag="a"
                            text
                            :large="$vuetify.breakpoint.xlOnly"
                            :icon="$vuetify.breakpoint.smAndDown"
                            :to="{
                                name: leaMaster ? 'AdminVvtEditMaster' : groupTemplates ? 'SettingsVvtGroupTemplatesEdit' : 'VvtEditProcessingActivity',
                                params: { id: selectedRecord.id },
                            }"
                        >
                            <v-icon v-if="loadedRecordData.canEdit" :left="$vuetify.breakpoint.mdAndUp">mdi-file-document-edit-outline</v-icon>
                            <v-icon v-else :left="$vuetify.breakpoint.mdAndUp">mdi-file-document-outline</v-icon>
                            <template v-if="$vuetify.breakpoint.mdAndUp">
                                <span v-if="loadedRecordData.canEdit" v-t="groupTemplates ? 'vvtProcessEditor.actions.editTemplate' : 'vvtProcessEditor.actions.edit'" />
                                <span v-else v-t="groupTemplates ? 'vvtProcessEditor.actions.openTemplate' : 'vvtProcessEditor.actions.open'" />
                            </template>
                            
                        </v-btn>
                    </template>
                    <span v-t="groupTemplates ? 'vvtProcessEditor.actions.openTemplate' : 'vvtProcessEditor.actions.open'"></span>
                </v-tooltip>
                <v-tooltip
                    :disabled="$vuetify.breakpoint.mdAndUp && loadedRecordData.canEditExtended"
                    :color="loadedRecordData.canEditExtended ? 'primary' : 'grey'"
                    :nudge-left="$vuetify.breakpoint.smAndDown ? -110 : null"
                    bottom>
                    <template v-slot:activator="{ on: onToolTip }">
                        <div
                            v-on="onToolTip"
                            class="d-inline-block">
                            <v-menu
                                :disabled="!loadedRecordData.canEditExtended" offset-y>
                                <template v-slot:activator="{ on: onMenu }">
                                    <v-btn
                                        v-on="onMenu"
                                        :color="!loadedRecordData.canEditExtended || $wait.is('toggleRecordStatus') ? 'grey lighten-1' : 'white'"
                                        :large="$vuetify.breakpoint.xlOnly"
                                        :icon="$vuetify.breakpoint.smAndDown"
                                        text
                                        :depressed="$wait.is('toggleRecordStatus')"
                                        :loading="$wait.is('toggleRecordStatus')"
                                        :ripple="loadedRecordData.canEditExtended"
                                    >
                                        <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-state-machine</v-icon>
                                        <span v-if="$vuetify.breakpoint.mdAndUp" v-t="'vvtProcessEditor.actions.changeStatus'" />
                                    </v-btn>
                                </template>
                                <v-list v-if="!$wait.is('toggleRecordStatus')" dense>
                                    <template v-for="statusItem in statusItems">
                                        <v-list-item
                                            :key="statusItem.key"
                                            v-if="selectedRecord.status && selectedRecord.status.toLowerCase() !== statusItem"
                                            @click.stop="toggleRecordStatus(statusItem)"
                                        >
                                            >
                                            <v-list-item-title class="primary--text">{{
                                                $t('states.' + statusItem)
                                            }}</v-list-item-title>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <span v-if="loadedRecordData.canEditExtended" v-t="'vvtProcessEditor.actions.changeStatus'" />
                    <span v-else v-t="'notAuthorizedAction'" />
                </v-tooltip>
                <v-tooltip
                    v-if="$hasRole('ROLE_EXPORTER') && !groupTemplates && !hideDetails"
                    :disabled="$vuetify.breakpoint.mdAndUp || !loadedRecordData"
                    color="primary"
                    bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="$wait.is('fetch record')"
                            v-on="on"
                            color="white"
                            tag="a"
                            :large="$vuetify.breakpoint.xlOnly"
                            :icon="$vuetify.breakpoint.smAndDown"
                            text
                            @click.stop="exportDialog = true"
                        >
                            <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-file-export-outline</v-icon>
                            <span v-if="$vuetify.breakpoint.mdAndUp" v-t="'vvtProcessEditor.actions.export'" />
                        </v-btn>
                    </template>
                    <span v-t="'vvtProcessEditor.actions.export'"></span>
                </v-tooltip>
                <v-tooltip
                    :disabled="$vuetify.breakpoint.mdAndUp && loadedRecordData.canDelete"
                    :color="loadedRecordData.canDelete ? 'primary' : 'grey'"
                    :nudge-left="$vuetify.breakpoint.smAndDown ? -150 : null"
                    bottom>
                    <template v-slot:activator="{ on }">
                        <div
                            v-on="on"
                            class="d-inline-block">
                                <v-btn
                                    :color="!loadedRecordData.canDelete ? 'grey lighten-1' : 'white'"
                                    tag="a"
                                    :large="$vuetify.breakpoint.xlOnly"
                                    :icon="$vuetify.breakpoint.smAndDown"
                                    text
                                    @click.stop="(loadedRecordData.canDelete || loadedRecordData.canDeleteWithoutPrecondition) && deleteRecord()"
                                    :ripple="loadedRecordData.canDelete || loadedRecordData.canDeleteWithoutPrecondition"
                                >
                                    <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-trash-can-outline</v-icon>
                                    <span v-if="$vuetify.breakpoint.mdAndUp" v-t="leaMaster ? 'vvtProcessEditor.actions.deleteMaster' : groupTemplates ? 'vvtProcessEditor.actions.deleteTemplate.title' : 'vvtProcessEditor.actions.delete.title'" />
                                </v-btn>
                        </div>
                    </template>
                    <span v-t="deletionHintText" />
                </v-tooltip>
                <v-menu
                    v-if="$devMode" 
                    offset-y
                    >
                    <template v-slot:activator="{ on: onMenu }">
                        <v-btn
                            v-on="onMenu"
                            color="orange"
                            :large="$vuetify.breakpoint.xlOnly"
                            :icon="$vuetify.breakpoint.smAndDown"
                            text
                            :depressed="$wait.is(['fetch history', 'fetch version'])"
                            :loading="$wait.is(['fetch history', 'fetch version'])"
                            ripple
                            @click.stop="fetchRevisions()"
                        >
                            <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-history</v-icon>
                            <span v-if="$vuetify.breakpoint.mdAndUp" v-t="'Version'" />
                        </v-btn>
                    </template>
                    <v-list v-if="!$wait.is('fetch history') && revisions.length" dense>
                        <div v-for="(revision, index) in revisions" :key="revision.rev">
                            <v-list-item
                                @click.stop="fetchVersion(revision.rev)"
                                three-line
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="primary--text font-weight-bold" :class="{'success--text': index === 0}">Version {{ revision.rev }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ getDate(revision.changeDate) }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ revision.changedBy }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            color="white"
                            outlined
                            fab
                            small
                            :elevation="2"
                            class="ma-2"
                            @click="$emit('close-record')"
                        >
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span v-text="$t('vvtProcessEditor.actions.closePreview')"></span>
                </v-tooltip>
            </v-toolbar>
            <v-sheet elevation="2" class="pb-0 mb-3" ref="previewHeadlineRow">
                <v-card-title v-if="selectedRecord.internalId || selectedRecord.name" class="primary--text d-block text-no-wrap text-truncate">
                    <span v-if="selectedRecord.internalId" class="mr-2">{{ selectedRecord.internalId }} |</span>{{ selectedRecord.name }}
                </v-card-title>
                <v-card-subtitle v-if="selectedRecord.companyName" class="primary--text">
                    <v-avatar v-if="company && company.countryCode" size="18" class="mr-1">
                        <img
                            :src="`${$publicPath}images/countries/${company.countryCode.toLowerCase()}.svg`"
                            :alt="selectedRecord.countryName"
                        >
                    </v-avatar> {{ selectedRecord.companyName }}
                </v-card-subtitle>
            </v-sheet>
            <div class="pt-0" :style="previewStyle">
                <v-card-text class="py-0">
                    <v-container class="pa-0" fluid>
                        <v-row justify="space-between" class="text-smaller">
                            <v-col cols="6" md="2" class="flex-grow-1 flex-shrink-0">
                                <v-tooltip bottom color="primary">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on"><strong v-t="'updated'" /><br /><span v-text="loadedRecordData && loadedRecordData.updatedAt ? getDate(loadedRecordData.updatedAt) : '-'" /></div>
                                    </template>
                                    <div>
                                        <template v-if="loadedRecordData">
                                            <strong>{{ $t('updated') }}</strong><br /><span v-if="loadedRecordData.updatedAt">{{ getDate(loadedRecordData.updatedAt) }}</span><br />
                                            <span v-if="loadedRecordData.updatedBy && loadedRecordData.updatedBy.fullName"><strong>{{ $t('by') }}</strong> {{ loadedRecordData.updatedBy.fullName }}</span><br />
                                        </template>
                                        <strong>{{ $t('created') }}</strong><br />{{ getDate(selectedRecord.createdAt) }}<br />
                                        <template v-if="loadedRecordData && loadedRecordData.createdBy && loadedRecordData.createdBy.fullName">
                                            <strong>{{ $t('by') }}</strong> {{ loadedRecordData.createdBy.fullName }}
                                        </template>
                                    </div>
                                </v-tooltip>
                            </v-col>
                            <v-col v-if="loadedRecordData && loadedRecordData.resubmission" cols="6" md="2" class="flex-grow-1 flex-shrink-0">
                                <strong v-t="'vvtProcessEditor.basics.resubmission.label'" />
                                <strong 
                                    v-if="resubmissionStatus"
                                    class="ml-1"
                                    v-t="'vvtProcessEditor.basics.resubmission.' + resubmissionStatus"
                                /><br /><span 
                                    :class="{
                                        'error--text': resubmissionStatus === 'exceeded',
                                        'warning--text': resubmissionStatus === 'upcoming',
                                    }">{{ getResubmissionDate(loadedRecordData.resubmission) }}</span>
                            </v-col>
                            <v-col v-if="getCompanyGroupSettings('useAuthorityReportRelevance') && loadedRecordData" cols="6" md="2" style="min-width:214px;" class="flex-grow-1 flex-shrink-0">
                                <strong v-t="'vvtProcessEditor.basics.authorityReportRelevance.hint'" /><br /><span v-t="loadedRecordData.authorityReportRelevance ? 'yes' : 'no'" />
                            </v-col>
                            <v-col v-if="loadedRecordData" cols="6" md="2" class="flex-grow-1 flex-shrink-0">
                                <strong v-t="'sourceLanguage'" /><br />{{ getLanguage(loadedRecordData.language) }}
                            </v-col>
                            <v-col v-if="selectedRecord && selectedRecord.priorityName" cols="6" md="2" lg="1" style="min-width:80px;" class="flex-grow-1 flex-shrink-0">
                                <strong v-t="'vvtProcessEditor.basics.priority.label'" /><br />
                                <span 
                                    class="white--text px-3 caption font-weight-bold d-inline-block"
                                    :class="selectedRecord.priorityName === 'HIGH' ? 'red accent-4' : selectedRecord.priorityName === 'MEDIUM' ? 'orange accent-4' : 'green accent-4' "
                                    v-t="'vvtProcessEditor.basics.priority.priorities.' + selectedRecord.priorityName" />
                            </v-col>
                            <v-col v-if="selectedRecord && selectedRecord.status" cols="6" md="2" lg="1" style="min-width:100px;" class="flex-grow-1 flex-shrink-0">
                                <strong v-t="'vvtProcessEditor.basics.status.label'" /><br />
                                <span 
                                    class="black--text px-3 caption font-weight-bold d-inline-block"
                                    :class="getStatusColor(selectedRecord.status.toLowerCase())"
                                    v-t="'states.' + selectedRecord.status.toLowerCase()" />
                            </v-col>
                            <v-col v-if="selectedRecord.domainTitle" cols="6" md="2">
                                <strong v-t="'domain'" /><br />{{ selectedRecord.domainTitle }}
                            </v-col>
                            <v-col v-if="selectedRecord.departmentTitle" cols="6" md="2">
                                <strong v-t="'vvtProcessEditor.basics.department.shortcut'" /><br />{{ selectedRecord.departmentTitle }}
                            </v-col>
                            <v-col v-if="countriesOfAffectedPersons" cols="12" md="6">
                                <strong v-t="'vvtProcessEditor.basics.countriesOfAffectedPersons.label'" /><br /><div v-html="countriesOfAffectedPersons" />
                            </v-col>
                        </v-row>
                        <v-row justify="space-between" class="text-smaller">
                            <template v-if="loadedRecordData.controller || loadedRecordData.processor">
                                <v-col v-if="loadedRecordData.controller" cols="12" md="6">
                                    <strong v-t="'vvtProcessEditor.basics.controller.label'" /><br />
                                    <div v-if="company">
                                        {{ company.title }}
                                    </div>
                                </v-col>
                                <v-col v-if="loadedRecordData.processor" cols="12" md="6">
                                    <strong v-t="'vvtProcessEditor.basics.processor.label'" /><br /><div v-t="'vvtProcessEditor.basics.processor.hint'" />
                                </v-col>
                            </template>
                        </v-row>

                        <v-row 
                            v-if="loadedRecordData.processor && 
                                (
                                    loadedRecordData.processorCompanies || 
                                    loadedRecordData.processorOutsideCompanies ||
                                    (
                                        loadedRecordData.processorVendorCompanies && 
                                        getCompanyGroupSettings('useVendor')
                                    ) 
                                )" 
                            justify="space-between"
                            >
                            <v-col v-if="loadedRecordData.processorCompanies" cols="12" :lg="getCompanyGroupSettings('useVendor') && loadedRecordData.processorVendorCompanies ? 4 : 6">
                                <strong v-t="'vvtProcessEditor.basics.processorCompanies.shortcut'" /><br />
                                <GenericItemList
                                    disableSorting
                                    returnObject
                                    :items="companiesById(loadedRecordData.processorCompanies)"
                                    :itemText="'title'"
                                    dense
                                    :ripple="false"
                                    inactive
                                    class="ml-n3"
                                />
                            </v-col>
                            <v-col v-if="getCompanyGroupSettings('useVendor') && loadedRecordData.processorVendorCompanies" cols="12" lg="4">
                                <strong v-t="'vvtProcessEditor.basics.processorVendorCompanies.shortcut'" /><br />
                                <GenericItemList
                                    disableSorting
                                    returnObject
                                    :items="vendorsById(loadedRecordData.processorVendorCompanies)"
                                    :itemText="'name'"
                                    dense
                                    :ripple="false"
                                    inactive
                                    class="ml-n3"
                                />
                            </v-col>
                            <v-col v-if="loadedRecordData.processorOutsideCompanies" cols="12" :lg="getCompanyGroupSettings('useVendor') && loadedRecordData.processorVendorCompanies ? 4 : 6">
                                <strong v-t="'vvtProcessEditor.basics.processorOutsideCompanies.shortcut'" /><br /><div v-html="$nl2br(loadedRecordData.processorOutsideCompanies)" />
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedRecord.description">
                            <v-col cols="12">
                                <strong v-t="'vvtProcessEditor.basics.description.shortcut'" /><br />
                                <template v-if="selectedRecord.description.length > 200 && !fullDescription">
                                    <div>{{ $htmlSnippet(selectedRecord.description, 200) }} <v-icon @click="fullDescription = true">mdi-unfold-more-horizontal</v-icon></div>
                                </template>
                                <div v-else>{{ selectedRecord.description }}</div>
                            </v-col>
                        </v-row>
                        <v-row v-if="loadedRecordData && loadedRecordData.processDocumentation">
                            <v-col cols="12">
                                <strong v-t="'vvtProcessEditor.basics.processDocumentation.label'" /><br />
                                <template v-if="loadedRecordData.processDocumentation.length > 200 && !fullProcessDocumentation">
                                    <div>{{ $htmlSnippet(loadedRecordData.processDocumentation, 200) }} <v-icon @click="fullProcessDocumentation = true">mdi-unfold-more-horizontal</v-icon></div>
                                </template>
                                <div v-else>{{ loadedRecordData.processDocumentation }}</div>
                            </v-col>
                        </v-row>
                        <v-row v-if="loadedRecordData && (processOwner || processEditor || processCoordinator)" justify="space-between">
                            <v-col v-if="processOwner">
                                <strong v-t="'vvtProcessEditor.basics.contactItemsOwner.title'" /><br /><div v-html="processOwner" />
                            </v-col>
                            <v-col v-if="processEditor">
                                <strong v-t="'vvtProcessEditor.basics.contactItemsEditor.title'" /><br /><div v-html="processEditor" />
                            </v-col>
                            <v-col v-if="processCoordinator">
                                <strong v-t="'vvtProcessEditor.basics.contactItemsCoordinator.title'" /><br /><div v-html="processCoordinator" />
                            </v-col>
                        </v-row>
                        <v-row v-if="internalKeywords && internalKeywords.length" justify="space-between">
                            <v-col>
                                <strong v-t="'vvtProcessEditor.basics.internalKeywords.label'" /><br /><v-chip v-for="(keyword, index) in internalKeywords.map(x => x.title)" :key="'internalKeyword_' + index" small class="ma-1">{{ keyword }}</v-chip>
                            </v-col>
                        </v-row>
                        <v-row v-if="tools && tools.length">
                            <v-col cols="12">
                                <strong v-t="'vvtProcessEditor.basics.tools.shortcut'" /><br />
                                <v-chip v-for="(tool, index) in tools" :key="'tool_' + index" small class="ma-1">{{ tool.title }}</v-chip>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-expansion-panels
                    v-if="!$wait.is('fetch record') && !hideDetails"
                    v-model="openPreviewPanels"
                    multiple
                    flat
                    tile
                    focusable
                    hover
                    class="mt-3"
                    >
                    <v-expansion-panel v-show="loadedRecordData.enclosures && loadedRecordData.enclosures.length" class="mb-3">
                        <v-expansion-panel-header hide-actions ripple color="accent" class="px-3 py-2 text-h6 white--text">
                            <div>
                                <v-icon
                                    color="white"
                                    class="pr-2 mt-n1"
                                    :class="{ 'rotate-90': openPreviewPanels.includes(0) }"
                                    >keyboard_arrow_right</v-icon
                                >
                                <span v-t="'vvtProcessEditor.basics.enclosures.title'" />
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="secondary">
                            <div class="mt-6">
                                <div 
                                    v-for="(enclosure, index) in loadedRecordData.enclosures" :key="'enclosure_c_' + index">
                                    <v-card-text class="py-0">
                                        <div><strong v-t="'vvtProcessEditor.basics.enclosures.enclosureItem.title.shortcut'" /><br /><div v-html="enclosure.title" /></div>
                                        <div class="my-3"><strong v-t="'vvtProcessEditor.basics.enclosures.enclosureItem.description.shortcut'" /><br /><div v-html="enclosure.description" /></div>
                                        <template v-if="enclosure.files && enclosure.files.length">
                                            <div><strong v-t="'vvtProcessEditor.basics.enclosures.enclosureItem.files.shortcut'" /><br /></div>
                                            <v-list dense>
                                                <v-list-item
                                                    v-for="file in enclosure.files"
                                                    :key="file.uuid"
                                                    @click="$getFile(`/api/enclosures/download/${file.uuid}`, file.filename, true)"
                                                    :disabled="$wait.is('fetching file')"
                                                >
                                                <v-list-item-icon>
                                                    <v-icon color="primary">mdi-cloud-download</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="file.name"></v-list-item-title>
                                                </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </template>
                                    </v-card-text>
                                    <v-divider v-if="index !== loadedRecordData.enclosures.length - 1" :key="'enclosure_d_' + index" class="my-12 grey lighten-2 elevation-2"></v-divider>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-show="loadedRecordData.groupsOfPersons && loadedRecordData.groupsOfPersons.length" class="mb-3">
                        <v-expansion-panel-header hide-actions ripple color="accent" class="px-3 py-2 text-h6 white--text">
                            <div>
                                <v-icon
                                    color="white"
                                    class="pr-2 mt-n1"
                                    :class="{ 'rotate-90': openPreviewPanels.includes(1) }"
                                    >keyboard_arrow_right</v-icon
                                >
                                <span v-t="'vvtProcessEditor.steps.2.tabTitle'" />
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="secondary">
                            <div class="mt-6">
                                <div 
                                    v-for="(group, index) in loadedRecordData.groupsOfPersons" :key="'groupOfPersons_c_' + index">
                                    <v-card-text class="py-0">
                                        <GroupOfPersons
                                            :itemKey="index"
                                            :group="group"
                                            :groupsOfPersonItems="groupsOfPersonItems"
                                            :personalDataCategoryItems="personalDataCategoryItems"
                                            :personalDataItems="personalDataItems"
                                        />
                                    </v-card-text>
                                    <v-divider v-if="index !== loadedRecordData.groupsOfPersons.length - 1" :key="'groupOfPersons_d_' + index" class="my-12 grey lighten-2 elevation-2"></v-divider>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-show="loadedRecordData.purposeOfProcessing && loadedRecordData.purposeOfProcessing.length" class="mb-3 mt-0">
                        <v-expansion-panel-header hide-actions ripple color="accent" class="px-3 py-2 text-h6 white--text">
                            <div>
                                <v-icon
                                    color="white"
                                    class="pr-2 mt-n1"
                                    :class="{ 'rotate-90': openPreviewPanels.includes(2) }"
                                    >keyboard_arrow_right</v-icon
                                >
                                <span v-t="'vvtProcessEditor.steps.3.tabTitle'" />
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="secondary">
                            <div class="mt-6">
                                <div 
                                    v-for="(purpose, index) in loadedRecordData.purposeOfProcessing" :key="'purposeOfProcessing_c_' + index">
                                    <v-card-text class="py-0">
                                        <PurposeOfProcessing
                                            :itemKey="index"
                                            :purpose="purpose"
                                            :legalityItems="legalityItems"
                                            :nationalLegalObligationItems="nationalLegalObligationItems"
                                            :flexibilityClauseItems="flexibilityClauseItems"
                                            :nationalDataProtectionLawItems="nationalDataProtectionLawItems"
                                            @send-download="$getFile(`/api/enclosures/download/${$event.uuid}`, $event.filename, true)"
                                        />
                                    </v-card-text>
                                    <v-divider v-if="index !== loadedRecordData.purposeOfProcessing.length - 1" :key="'purposeOfProcessing_d_' + index" class="my-12 grey lighten-2 elevation-2"></v-divider>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-show="loadedRecordData.deletionPractise && loadedRecordData.deletionPractise.length" class="mb-3 mt-0">
                        <v-expansion-panel-header hide-actions ripple color="accent" class="px-3 py-2 text-h6 white--text">
                            <div>
                                <v-icon
                                    color="white"
                                    class="pr-2 mt-n1"
                                    :class="{ 'rotate-90': openPreviewPanels.includes(3) }"
                                    >keyboard_arrow_right</v-icon
                                >
                                <span v-t="'vvtProcessEditor.steps.4.tabTitle'" />
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="secondary">
                            <div class="mt-6">
                                <div 
                                    v-for="(deletionPractise, index) in loadedRecordData.deletionPractise" :key="'deletionPractise_c_' + index">
                                    <v-card-text class="py-0">
                                        <DeletionPractise
                                            :itemKey="index"
                                            :deletionPractise="deletionPractise"
                                            :groupsOfPersons="loadedRecordData.groupsOfPersons"
                                            :groupsOfPersonItems="groupsOfPersonItems"
                                            :personalDataCategoryItems="personalDataCategoryItems"
                                            :personalDataItems="personalDataItems"
                                            :deletionItems="deletionItems"
                                            @send-download="$getFile(`/api/enclosures/download/${$event.uuid}`, $event.filename, true)"
                                        />
                                    </v-card-text>
                                    <v-divider v-if="index !== loadedRecordData.deletionPractise.length - 1" :key="'deletionPractise_d_' + index" class="my-12 grey lighten-2 elevation-2"></v-divider>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-show="loadedRecordData.internalRecipients && loadedRecordData.internalRecipients.length" class="mb-3 mt-0">
                        <v-expansion-panel-header hide-actions ripple color="accent" class="px-3 py-2 text-h6 white--text">
                            <div>
                                <v-icon
                                    color="white"
                                    class="pr-2 mt-n1"
                                    :class="{ 'rotate-90': openPreviewPanels.includes(4) }"
                                    >keyboard_arrow_right</v-icon
                                >
                                <span v-t="'vvtProcessEditor.recipients.internal.headline'" /> <span v-t="'vvtProcessEditor.recipients.internal.subheadline'" />
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="secondary">
                            <div class="mt-6">
                                <template>
                                        <div 
                                            v-for="(internalRecipient, index) in loadedRecordData.internalRecipients" :key="'internalRecipient_c_' + index">
                                            <v-card-text class="py-0">
                                                <InternalRecipient
                                                    :itemKey="index"
                                                    :recipient="internalRecipient"
                                                    :typeOfInternalRecipientItems="typeOfInternalRecipientItems"
                                                    :departmentCategoryItems="departmentCategoryItems"
                                                    :groupsOfPersons="loadedRecordData.groupsOfPersons"
                                                    :groupsOfPersonItems="groupsOfPersonItems"
                                                    :personalDataCategoryItems="personalDataCategoryItems"
                                                    :personalDataItems="personalDataItems"
                                                    :useDataCategories="getCompanyGroupSettings('useDataCategories')"
                                                />
                                            </v-card-text>
                                            <v-divider v-if="index !== loadedRecordData.internalRecipients.length - 1" :key="'internalRecipient_d_' + index" class="my-12 grey lighten-2 elevation-2"></v-divider>
                                        </div>
                                </template>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-show="loadedRecordData.corporationRecipients && loadedRecordData.corporationRecipients.length" class="mb-3 mt-0">
                        <v-expansion-panel-header hide-actions ripple color="accent" class="px-3 py-2 text-h6 white--text">
                            <div>
                                <v-icon
                                    color="white"
                                    class="pr-2 mt-n1"
                                    :class="{ 'rotate-90': openPreviewPanels.includes(5) }"
                                    >keyboard_arrow_right</v-icon
                                >
                                <span v-t="'vvtProcessEditor.recipients.corporation.headline'" /> <span v-t="'vvtProcessEditor.recipients.corporation.subheadline'" />
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="secondary">
                            <div class="mt-6">
                                <div 
                                    v-for="(corporationRecipient, index) in loadedRecordData.corporationRecipients" :key="'corporationRecipient_c_' + index">
                                    <v-card-text class="py-0">
                                        <CorporationRecipient
                                            :itemKey="index"
                                            :recipient="corporationRecipient"
                                            :groupsOfPersons="loadedRecordData.groupsOfPersons"
                                            :groupsOfPersonItems="groupsOfPersonItems"
                                            :appropriateSafeguardsItems="appropriateSafeguardsItems"
                                            :personalDataCategoryItems="personalDataCategoryItems"
                                            :personalDataItems="personalDataItems"
                                            :legalityItems="legalityItems"
                                            :nationalLegalObligationItems="recipientNationalLegalObligationItems"
                                            :flexibilityClauseItems="flexibilityClauseItems"
                                            :nationalDataProtectionLawItems="nationalDataProtectionLawItems"
                                            :useDataCategories="getCompanyGroupSettings('useDataCategories')"
                                        />
                                    </v-card-text>
                                    <v-divider v-if="index !== loadedRecordData.corporationRecipients.length - 1" :key="'corporationRecipient_d_' + index" class="my-12 grey lighten-2 elevation-2"></v-divider>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-show="loadedRecordData.externalRecipients && loadedRecordData.externalRecipients.length" class="mb-3 mt-0">
                        <v-expansion-panel-header hide-actions ripple color="accent" class="px-3 py-2 text-h6 white--text">
                            <div>
                                <v-icon
                                    color="white"
                                    class="pr-2 mt-n1"
                                    :class="{ 'rotate-90': openPreviewPanels.includes(6) }"
                                    >keyboard_arrow_right</v-icon
                                >
                                <span v-t="'vvtProcessEditor.recipients.external.headline'" /> <span v-t="'vvtProcessEditor.recipients.external.subheadline'" />
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="secondary">
                            <div class="mt-6">
                                <div 
                                    v-for="(externalRecipient, index) in loadedRecordData.externalRecipients" :key="'externalRecipient_c_' + index">
                                    <v-card-text class="py-0">
                                        <ExternalRecipient
                                            :itemKey="index"
                                            :recipient="externalRecipient"
                                            :groupsOfPersons="loadedRecordData.groupsOfPersons"
                                            :groupsOfPersonItems="groupsOfPersonItems"
                                            :typeOfExternalRecipientItems="typeOfExternalRecipientItems"
                                            :appropriateSafeguardsItems="appropriateSafeguardsItems"
                                            :personalDataCategoryItems="personalDataCategoryItems"
                                            :personalDataItems="personalDataItems"
                                            :legalityItems="legalityItems"
                                            :nationalLegalObligationItems="recipientNationalLegalObligationItems"
                                            :flexibilityClauseItems="flexibilityClauseItems"
                                            :nationalDataProtectionLawItems="nationalDataProtectionLawItems"
                                            :useDataCategories="getCompanyGroupSettings('useDataCategories')"
                                        />
                                    </v-card-text>
                                    <v-divider v-if="index !== loadedRecordData.externalRecipients.length - 1" :key="'externalRecipient_d_' + index" class="my-12 grey lighten-2 elevation-2"></v-divider>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel class="mb-3 mt-0" v-show="loadedRecordData.tom || loadedRecordData.tools && (getCompanyGroupSettings('useCompanyTomInRecord') || getCompanyGroupSettings('useToolTomInRecord'))">
                        <v-expansion-panel-header hide-actions ripple color="accent" class="px-3 py-2 text-h6 white--text">
                            <div>
                                <v-icon
                                    color="white"
                                    class="pr-2 mt-n1"
                                    :class="{ 'rotate-90': openPreviewPanels.includes(7) }"
                                    >keyboard_arrow_right</v-icon
                                >
                                <span v-t="'vvtProcessEditor.steps.6.tabTitle'" />
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="secondary">
                            <div class="mt-6" v-if="getCompanyGroupSettings('useCompanyTomInRecord')">
                                <v-card-text v-if="!loadedRecordData.tom || !loadedRecordData.tom.isSet" class="py-0" v-t="'vvtProcessEditor.steps.6.companyTomNotSet'" />
                                <template v-else>
                                    <v-card-title>
                                        <v-icon>mdi-security</v-icon>
                                        <span class="caption pl-1">{{ $t('vvtProcessEditor.steps.6.tabTitle') }}</span>
                                    </v-card-title>
                                    <v-card-text class="py-0">
                                        <Tom :tom="loadedRecordData.tom" :language="loadedRecordData.language" />
                                    </v-card-text>
                                </template>
                            </div>
                            <div class="mt-6" v-if="getCompanyGroupSettings('useToolTomInRecord')">
                                <v-card-text v-if="!loadedRecordData.tools || !loadedRecordData.tools.length" class="py-0" v-t="'vvtProcessEditor.steps.6.toolTomNotSet'" />
                                <template v-else>
                                    <v-card-title>
                                        <v-icon>mdi-server-security</v-icon>
                                        <span class="caption pl-1">{{ $t('vvtProcessEditor.basics.toolsHeadline') }}</span>
                                    </v-card-title>
                                    <v-card-text class="py-0">
                                        <ToolTom
                                            :useVendor="getCompanyGroupSettings('useVendor')"
                                            hide-default-header
                                            small />
                                    </v-card-text>
                                </template>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel class="mb-3 mt-0">
                        <v-expansion-panel-header hide-actions ripple color="accent" class="px-3 py-2 text-h6 white--text">
                            <div>
                                <v-icon
                                    color="white"
                                    class="pr-2 mt-n1"
                                    :class="{ 'rotate-90': openPreviewPanels.includes(8) }"
                                    >keyboard_arrow_right</v-icon
                                >
                                <span v-t="'vvtProcessEditor.risk.headline'" />
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="secondary">
                            <div class="mt-6">
                                <v-card-text class="py-0">
                                    <Risk
                                        v-if="loadedRecordData.riskAssessment"
                                        :data="loadedRecordData.riskAssessment"
                                    />
                                    <v-container class="pa-0" fluid>
                                        <v-row>
                                            <v-col>
                                                <strong v-t="'vvtProcessEditor.risk.summary.dpiaRequirement.label'" /><br />
                                                <v-alert dense border="left" colored-border elevation="1" class="mt-1" :type="loadedRecordData.dpiaRequirement === null || loadedRecordData.dpiaRequirement === 'HIGH' ? 'warning' : 'success'">
                                                    <span v-if="loadedRecordData.dpiaRequirement === null">
                                                        {{ $t('N/A') }}
                                                    </span>
                                                    <span v-else>
                                                        {{ $t('vvtProcessEditor.risk.summary.dpiaRequirement.dpiaRequirements.' + loadedRecordData.dpiaRequirement.toLowerCase()) }}
                                                    </span>
                                                </v-alert>
                                            </v-col>
                                            <v-col v-if="loadedRecordData.riskAssessmentNote" cols="12">
                                                <strong v-t="'vvtProcessEditor.risk.summary.riskAssessmentNote.label'" /><br />
                                                {{ loadedRecordData.riskAssessmentNote }}
                                            </v-col>
                                            <v-col v-if="loadedRecordData.dpiaRequirement" cols="12">
                                                <strong  v-t="'vvtProcessEditor.risk.summary.dpiaCompleted.label'" /><br />
                                                <span v-if="loadedRecordData.dpiaCompleted">{{ $t('yes') }}</span>
                                                <span v-else>{{ $t('no') }}</span>
                                            </v-col>
                                            <v-col v-if="loadedRecordData.dpiaNote" cols="12">
                                                <strong v-t="'vvtProcessEditor.risk.summary.dpiaNote.label'" /><br />
                                                {{ loadedRecordData.dpiaNote }}
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-card>
        <v-dialog v-model="exportDialog" :width="$vuetify.breakpoint.mdAndUp ? 680 : '100%'">
            <VvtExportDialog
                v-if="!hideDetails"
                :exportType="exportTypeModel"
                :exportLanguage="exportLanguageModel"
                :exportFileType="exportFileTypeModel"
                :exportHistoryDate="exportHistoryDateModel"
                :selectedFilter="selectedFilter"
                single-document-export
                :is-single-controller-activity="loadedRecordData && loadedRecordData.controller"
                :is-single-processor-activity="loadedRecordData && loadedRecordData.processor"
                :minDate="minDate"
                @close="exportDialog = false"
                @process-export="processExport()"
                @changeExportType="exportTypeModel = $event"
                @changeLanguage="exportLanguageModel = $event"
                @changeExportFileType="exportFileTypeModel = $event"
                @changeExportHistoryDate="exportHistoryDateModel = $event"
            />
        </v-dialog>
    </div>
</template>

<script>
import http from '@/utils/axios';
import { getDetails } from '@/utils/auth';
import { mapGetters, mapActions } from 'vuex';
import camelCase from 'lodash/camelCase';
const VvtExportDialog = () => import(/* webpackChunkName: "VvtListExportDialog" */ './../ExportDialog.vue');
const GenericItemList = () => import(/* webpackChunkName: "GenericItemList" */ '@/components/generic/list/ItemList.vue');
import { parseISO, dateTypes, formatDate } from '@/utils/dateFns';
const GroupOfPersons = () => import('./GroupOfPersons.vue');
const PurposeOfProcessing = () => import('./PurposeOfProcessing.vue');
const DeletionPractise = () => import('./DeletionPractise.vue');
const InternalRecipient = () => import('./InternalRecipient.vue');
const CorporationRecipient = () => import('./CorporationRecipient.vue');
const ExternalRecipient = () => import('./ExternalRecipient.vue');
const ToolTom = () => import('@/components/vvt/ProcessEditor/Tom/Tools');
const Tom = () => import('./Tom.vue');
const Risk = () => import('./Risk.vue');

const LEA_VVT_LIST_PREVIEW_PANELS = 'LEA_VVT_LIST_PREVIEW_PANELS';
const DEFAULT_RECORD = {
        canEditExtended: false,
        canDelete: false,
        internalKeywords: [],
        countriesOfAffectedPersons: [],
        status: null,
        priority: null,
        dpiaRequirement: null
    };

export default {
    name: 'VvtListRecordPreviewIndex',
    props: {
        selectedRecord: {
            type: Object,
            default: null
        },
        selectedFilter: {
            type: [Object, String],
            default: null
        },
        vvtListPreviewTopPosition: {
            type: Number,
            default: 0
        },
        toolbarHeight: {
            type: Number,
            default: 64
        },
        leaMaster: {
            type: Boolean,
            default: false
        },
        groupTemplates: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    components: {
        GroupOfPersons,
        PurposeOfProcessing,
        DeletionPractise,
        InternalRecipient,
        CorporationRecipient,
        ExternalRecipient,
        Risk,
        ToolTom,
        Tom,
        VvtExportDialog,
        GenericItemList
    },
    data() {
        return {
            exportDialog: false,
            exportTypeModel: null,
            exportLanguageModel: null,
            exportFileTypeModel: null,
            exportHistoryDateModel: null,
            statusItems: ['draft', 'review', 'approval', 'release', 'finalised', 'archived'],
            loadedRecordData: DEFAULT_RECORD,
            openPreviewPanels: localStorage.getItem(LEA_VVT_LIST_PREVIEW_PANELS) ? JSON.parse(localStorage.getItem(LEA_VVT_LIST_PREVIEW_PANELS)) : [],
            fullDescription: false,
            fullProcessDocumentation: false,
            revisions: [],
            minDate: null
        };
    },
    computed: {
        ...mapGetters({
            countryByCode: 'countries/findByCountryCode',
            internalKeywordItems: 'companyInternalKeywords/getItems',
            toolItems: 'tools/getItems',
            groupsOfPersonItems: 'groupsOfPersons/getItems',
            personalDataItems: 'personalData/getItems',
            personalDataCategoryItems: 'personalDataCategories/getItems',
            legalityItems: 'legality/getItems',
            nationalLegalObligationItems: 'nationalLegalObligation/getItems',
            recipientNationalLegalObligationItems: 'recipientNationalLegalObligation/getItems',
            flexibilityClauseItems: 'flexibilityClause/getItemsWithoutCategory',
            nationalDataProtectionLawItems: 'nationalDataProtectionLaw/getItems',
            deletionItems: 'deletionPractise/getGdprItems',
            typeOfInternalRecipientItems: 'internalRecipientCategories/getItems',
            departmentCategoryItems: 'departmentCategories/getItems',
            appropriateSafeguardsItems: 'appropriateSafeguards/getItems',
            typeOfExternalRecipientItems: 'externalRecipientCategories/getItems',
            availableLanguages: 'languageSettings/getAvailableLanguages',
            getFilteredCompanies: 'companies/getFilteredCompanies',
            getCompanyGroupSettings: 'companyGroupSettings/get',
            getMultipleCompaniesById: 'companies/getMultipleById',
            getMultipleVendorsById: 'vendor/getMultipleById',
        }),
        currentUser() {
            return getDetails();
        },
        deletionHintText () {
            if (this.loadedRecordData.canDelete && this.loadedRecordData.canDeleteWithoutPrecondition) {
                return this.groupTemplates ? this.$t('vvtProcessEditor.actions.deleteTemplate.deleteConfirmation.text') : this.$t('vvtProcessEditor.actions.delete.deleteConfirmation.text');
            }
            if (!this.loadedRecordData.canDelete && this.loadedRecordData.canDeleteWithoutPrecondition) {
                return !this.groupTemplates && this.getCompanyGroupSettings('useAuthorityReportRelevance') && this.loadedRecordData.authorityReportRelevance ? this.$t('vvtProcessEditor.actions.delete.deleteConfirmation.textDisabledAlt') : this.$t('vvtProcessEditor.actions.delete.deleteConfirmation.textDisabled');
            }

            return 'notAuthorizedAction';
        },
        company () {
            if (!this.selectedRecord || !this.selectedRecord.companyId) {
                return null;
            }
            return this.getFilteredCompanies('all').find(x => x.id === this.selectedRecord.companyId)
        },
        countriesOfAffectedPersons() {
            let strCountry = '';
            if (!this.loadedRecordData) {
                return strCountry;
            }
            this.loadedRecordData.countriesOfAffectedPersons.map(
                (countryCode, index) => {
                    const country = this.countryByCode(countryCode);
                    const warning =
                        !country.eu &&
                        !country.eea &&
                        !country.safeThirdCountry ||
                        country.countryCode === 'US';
                    if (index > 0) {
                        strCountry = strCountry + ', ';
                    }
                    if (warning) {
                        strCountry =
                            strCountry +
                            '<span class="warning--text">' +
                            country.countryName +
                            '</span>';
                    } else {
                        strCountry =
                            strCountry +
                            '<span>' +
                            country.countryName +
                            '</span>';
                    }
                }
            );
            return strCountry;
        },
        processOwner () {
            return this.getContactPersons(1);
        },
        processEditor () {
            return this.getContactPersons(2);
        },
        processCoordinator () {
            return this.getContactPersons(3);
        },
        internalKeywords () {
            if (!this.loadedRecordData || !this.loadedRecordData.internalKeywords) {
                return null;
            }
            return this.internalKeywordItems.filter(x => this.loadedRecordData.internalKeywords.includes(x.id));
        },
        tools () {
            if (!this.loadedRecordData || !this.loadedRecordData.tools) {
                return null;
            }
            return this.toolItems.filter(x => this.loadedRecordData.tools.includes(x.id));
        },
        resubmissionStatus() {
            if (
                !this.loadedRecordData ||
                !this.loadedRecordData.resubmissionStatus
            ) {
                return false;
            }
            if (this.loadedRecordData.resubmissionStatus.exceeded) {
                return 'exceeded';
            }
            if (this.loadedRecordData.resubmissionStatus.upcoming) {
                return 'upcoming';
            }
            return false;
        },
        previewStyle() {
            return {
                height:
                    'calc(100vh - 188px - ' +
                    this.vvtListPreviewTopPosition +
                    'px)',
                overflowX: 'hidden',
                overflowY: 'auto',
            };
        },
    },
    created() {
        this.fetchRecord(this.selectedRecord.id);
    },
    methods: {
        ...mapActions({
            fetchCompanies: 'companies/fetchAll',
            fetchVendors: 'vendor/fetch',
            fetchCountries: 'countries/fetch',
            fetchInternalKeywordItems: 'companyInternalKeywords/fetch',
            fetchToolItems: 'tools/fetch',
            fetchGroupOfPersonItems: 'groupsOfPersons/fetch',
            fetchPersonalDataItems: 'personalData/fetch',
            fetchPersonalDataCategoryItems: 'personalDataCategories/fetch',
            fetchLegalityItems: 'legality/fetch',
            fetchNationalLegalObligationItems: 'nationalLegalObligation/fetch',
            fetchRecipientNationalLegalObligationItems: 'recipientNationalLegalObligation/fetch',
            fetchFlexibilityClauseItems: 'flexibilityClause/fetch',
            fetchNationalDataProtectionLawItems: 'nationalDataProtectionLaw/fetch',
            fetchDeletionPractiseItems: 'deletionPractise/fetch',
            fetchDepartmentCategories: 'departmentCategories/fetch',
            fetchInternalRecipientItems: 'internalRecipientCategories/fetch',
            fetchAppropriateSafeguardsItems: 'appropriateSafeguards/fetch',
            fetchExternalRecipientItems: 'externalRecipientCategories/fetch',
            fetchRecordById: 'companyProcessingActivities/fetchRecordById',
            setActivityStatus: 'companyProcessingActivities/setStatus',
            fetchDetailedToolItems: 'tools/fetchDetailed',
            clearDetailedToolItems: 'tools/clearDetailedItems',
        }),
        fetchMinDate () {
            let strDate = new Date();
            strDate.setDate(strDate.getDate() + 1);
            this.minDate = strDate.toISOString().substring(0, 10);
            if(this.selectedRecord) {
                this.$wait.start('loading min date');
                http.post('/api/fetchExportMinDate', JSON.stringify({classVersion: 2, companyId: this.selectedRecord.companyId, activityId: this.selectedRecord.id}))
                .then(response => {
                    if (response.data) {
                        let strDate = new Date(response.data).toISOString().substring(0, 10);
                        this.minDate = strDate;
                        this.$wait.end('loading min date');
                    }
                })
                .catch(() => {})
            }
        },
        fetchToolDetails () {
            if(this.loadedRecordData.tools && this.loadedRecordData.tools.length) {
                this.$wait.start('fetch tools details');
                this.fetchDetailedToolItems({tools: this.loadedRecordData.tools}).then(() => {
                    this.$wait.end('fetch tools details');
                })
            } else {
                this.clearDetailedToolItems();
            }
        },
        companiesById(arrIds) {
            return this.getMultipleCompaniesById(arrIds);
        },
        vendorsById(arrIds) {
            return this.getMultipleVendorsById(arrIds);
        },
        dbValueToLocale (val) {
            return camelCase(val);
        },
        getStatusColor (status) {
            if (status === 'draft') {
                return 'grey lighten-3';
            }
            if (status === 'review') {
                return 'yellow darken-1';
            }
            if (status === 'approval') {
                return 'orange';
            }
            if (status === 'release') {
                return 'orange darken-4 white--text';
            }
            if (status === 'finalised') {
                return 'green';
            }
            if (status === 'archived') {
                return 'grey';
            }
        },
        getContactPersons ($categoryId) {
            let strPersons = '';
            if (!this.loadedRecordData || !this.loadedRecordData.contactItems) {
                return strPersons;
            }
            const contactPersons = this.loadedRecordData.contactItems.filter(x => x.category === $categoryId);
            if (contactPersons && contactPersons.length) {
                contactPersons.map(
                    (person, index) => {
                        if (index > 0) {
                            strPersons = strPersons + '<br />';
                        }
                        let prefix = person.title ? person.title + ' ' : '';
                        strPersons = strPersons + '<span>' + prefix + person.firstName + ' ' + person.lastName +  '</span>';
                    }
                );
            }
            return strPersons;
        },
        async fetchRecord(id) {
            this.loadedRecordData = DEFAULT_RECORD;
            this.revisions = [];
            this.$wait.start('fetch record');
            await Promise.all([
                this.fetchCompanies(),
                this.fetchVendors(),
                this.fetchCountries(),
                this.fetchInternalKeywordItems(),
                this.fetchToolItems(),
                this.fetchGroupOfPersonItems(),
                this.fetchPersonalDataCategoryItems(),
                this.fetchPersonalDataItems(),
                this.fetchLegalityItems(),
                this.fetchNationalLegalObligationItems(),
                this.fetchRecipientNationalLegalObligationItems(),
                this.fetchFlexibilityClauseItems(),
                this.fetchNationalDataProtectionLawItems(),
                this.fetchDeletionPractiseItems(),
                this.fetchDepartmentCategories(),
                this.fetchInternalRecipientItems(),
                this.fetchAppropriateSafeguardsItems(),
                this.fetchExternalRecipientItems(),
            ]);
            this.fetchRecordById(id).then(record => {
                this.$wait.end('fetch record');
                this.loadedRecordData = {...this.selectedRecord, ...record};
                this.fetchToolDetails();
                if(this.$hasRole('ROLE_EXPORTER')) {
                    this.fetchMinDate();
                }
            });
        },
        fetchRevisions() {
            if (!this.selectedRecord || !this.selectedRecord.id) {
                return null;
            }
            this.$wait.start('fetch history');
            http(`/api/processingActivity/listRevisions/${this.selectedRecord.id}`).then((x) => {
                if(x && x.data) {
                    this.revisions = x.data;
                }
                this.$wait.end('fetch history');
            });
        },
        fetchVersion(revId) {
            if (!this.selectedRecord || !this.selectedRecord.id || !revId) {
                return null;
            }
            this.$wait.start('fetch version');
            http(`/api/processingActivity/revision/${this.selectedRecord.id}/${revId}`).then((x) => {
                if(x && x.data) {
                    // TODO
                    //this.revisions = x.data;
                    //this.$log(x.data.status);
                    //this.$log(x.data);
                }
                this.$wait.end('fetch version');
            });
        },
        processExport() {
            this.exportDialog = false;
            if ( (['authority_controller', 'authority_processor'].includes(this.exportTypeModel) && (this.exportFileTypeModel === 'pdf' || this.exportFileTypeModel === 'word'))
                || (this.exportTypeModel === 'internal' && this.exportFileTypeModel === 'excel')
            ) {
                this.$generateDownload({
                    companyName: this.selectedRecord.companyName,
                    recordId: this.selectedRecord.id,
                    exportType: this.exportTypeModel,
                    contentType: this.exportFileTypeModel,
                    exportHistoryDate: this.exportHistoryDateModel,
                    language: this.exportLanguageModel,
                    companyId: this.selectedRecord.companyId,
                    filterId: null
                });
            }
        },
        deleteRecord() {
            this.$nextTick(() => {
                this.$emit('deleteRecordConfirmation', {deletionText: this.deletionHintText});
            });
        },
        toggleRecordStatus(status) {
            this.$wait.start('toggleRecordStatus');
            this.setActivityStatus({
                id: this.selectedRecord.id,
                companyId: this.selectedRecord.companyId,
                status: status,
                removedStatus: this.selectedRecord.status.toLowerCase()
            }).then(r => {
                this.loadedRecordData = {...this.selectedRecord, ...r.data};
                this.$set(this.selectedRecord, 'status', r.data.status);
                this.$set(this.selectedRecord, 'canRead', r.data.canRead);
                this.$set(this.selectedRecord, 'canEdit', r.data.canEdit);
                this.$set(this.selectedRecord, 'canDelete', r.data.canDelete);
                this.$set(this.selectedRecord, 'canDeleteWithoutPrecondition', r.data.canDeleteWithoutPrecondition);
                this.$set(this.selectedRecord, 'canEditExtended', r.data.canEditExtended);
                this.$set(this.selectedRecord, 'canEditWorkflow', r.data.canEditWorkflow);
                this.$set(this.selectedRecord, 'authorityReportRelevance', r.data.authorityReportRelevance);
                this.$set(this.selectedRecord, 'createdBy', r.data.createdBy);
                this.$set(this.selectedRecord, 'updatedBy', r.data.updatedBy);
                this.$wait.end('toggleRecordStatus');
            });
        },
        getDate(date, format) {
            let strFormat = format ? format : dateTypes.fullDateTime;
            return formatDate(
                parseISO(date),
                strFormat
            );
        },
        getResubmissionDate (date) {
            return this.getDate(date, dateTypes.fullDate);
        },
        getLanguage (langCode) {
            let objLang = this.availableLanguages.find(x => x === langCode);
            if (objLang) {
                return this.$t('languages.' + objLang);
            }
            return null;
        },
    },
    watch: {
        selectedRecord(newVal) {
            this.fetchRecord(newVal.id);
        },
        openPreviewPanels (newVal, oldVal) {
            if (newVal !== oldVal) {
                localStorage.setItem(LEA_VVT_LIST_PREVIEW_PANELS, JSON.stringify(newVal));
            }
        },
    }
};
</script>
